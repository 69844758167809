import React from 'react'

import './GameChoices.css'

import Choice from './Choice/Choice'

import { GameStateType } from '../../../../../types/GameStateType'
import { GameModeType } from '../../../../../types/GameModeType'

import { usePoints } from '../../../../../Context/PointsContext'
import { useAuth } from '../../../../../Context/AuthContext'

import { checkAnswer, getRandomOptions } from '../../../../../helpers/quizHelpers'

interface GameChoicesProps {
  gameMode: GameModeType,
  correctAnswer: string,
  quizId: string,
  continent: string,
  nextQuiz: () => void,
  setGameState: (state: GameStateType) => void,
}

const GameChoices: React.FC<GameChoicesProps> = ({ gameMode, correctAnswer, quizId, continent, nextQuiz, setGameState }) => {
  const { currentPoints, gameStreak, handleCorrectAnswer, handleIncorrectAnswer } = usePoints();
  const { user } = useAuth();

  const options = getRandomOptions(correctAnswer, gameMode);

  if (options && options.length >= 1) {
    return (
      <div className='GameChoices'>
        {options.map((option, index) => (
          <Choice
            key={index}
            country={option}
            checkAnswer={() => checkAnswer({
              option,
              correctAnswer,
              quizId,
              continent,
              handleCorrectAnswer,
              handleIncorrectAnswer,
              nextQuiz,
              setGameState,
              currentPoints,
              gameStreak
            })}
            isCorrect={option === correctAnswer}
            showCorrect={user?.role === "admin"}
          />
        ))}
      </div>
    );
  }

  return <p>Loading options...</p>;
}

export default GameChoices