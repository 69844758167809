import React, { createContext, useContext, useState, ReactNode } from 'react';

import { GameStateType } from '../types/GameStateType';
import { GameModeType } from '../types/GameModeType';
import { GameDifficultyType } from '../types/GameDifficultyType';

interface GameContextType {
  gameState: GameStateType;
  setGameState: (state: GameStateType) => void;
  gameMode: GameModeType;
  setGameMode: (state: GameModeType) => void;
  gameDifficulty: GameDifficultyType; 
  setGameDifficulty: (state: GameDifficultyType) => void,
}

const defaultContextValue: GameContextType = {
  gameState: GameStateType.Instructions,
  setGameState: () => {},
  gameMode: GameModeType.EastAsia,
  setGameMode: () => {},
  gameDifficulty: GameDifficultyType.Any,
  setGameDifficulty: () => {},
};

const GameContext = createContext<GameContextType>(defaultContextValue);

interface GameProviderProps {
  children: ReactNode;
}

export const GameProvider: React.FC<GameProviderProps> = ({ children }) => {
  const [gameState, setGameState] = useState<GameStateType>(GameStateType.Instructions);
  const [gameMode, setGameMode] = useState<GameModeType>(GameModeType.EastAsia);
  const [gameDifficulty, setGameDifficulty] = useState<GameDifficultyType>(GameDifficultyType.Any);

  return (
    <GameContext.Provider value={{ 
        gameState, 
        setGameState, 
        gameMode, 
        setGameMode,
        gameDifficulty, 
        setGameDifficulty
      }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => useContext(GameContext);
