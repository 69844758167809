import { db } from '../firebase/firebase-config';
import { collection, orderBy, getDocs, updateDoc, runTransaction, where, startAfter, increment, getDoc, doc, setDoc, query, limit, addDoc } from 'firebase/firestore';

import { LeaderboardMemberType } from '../types/LeaderboardMemberType';

import { GameModeType } from '../types/GameModeType';
import { Quiz } from '../types/Quiz';

import { superRegions } from '../config/superRegions';
import { GameDifficultyType } from '../types/GameDifficultyType';

const USERS_DATABASE = collection(db, 'users')
const QUIZZES_DATABASE = collection(db, 'quizzes')
const QUIZZES_STATS_DOC = doc(db, 'quizzes', 'stats')

const QUIZ_COUNT = 10;

const fetchQuizzesByRegion = async (region: string, gameDifficulty: GameDifficultyType): Promise<Quiz[]> => {
  try {
    const quizCollectionRef = collection(QUIZZES_DATABASE, region, 'quizItems');
    let quizzes: Quiz[] = [];
    let fetchedCount = 0;

    while (fetchedCount < QUIZ_COUNT) {
      let q;
      
      const randomStartPoint = Math.random();
      
      if (gameDifficulty === GameDifficultyType.Any) {
        q = query(
          quizCollectionRef,
          orderBy("random"),
          startAfter(randomStartPoint),
          limit(QUIZ_COUNT - fetchedCount)
        );
      } else {
        q = query(
          quizCollectionRef,
          where("difficulty", "==", gameDifficulty.toLowerCase()),
          orderBy("random"),
          startAfter(randomStartPoint),
          limit(QUIZ_COUNT - fetchedCount)
        );
      }

      const snapshot = await getDocs(q);

      const batchQuizzes: Quiz[] = snapshot.docs.map(doc => ({
        id: doc.id,
        imageUrl: doc.data().imageUrl,
        country: doc.data().country,
        options: doc.data().options,
        continent: doc.data().continent,
        random: doc.data().random,
        difficulty: doc.data().difficulty
      }));

      quizzes = [...quizzes, ...batchQuizzes];
      fetchedCount += batchQuizzes.length;

      // If we didn't get enough quizzes, we'll loop around to the start
      if (batchQuizzes.length === 0) {
        break;
      }
    }

    return quizzes;
  } catch (error) {
    console.error(`Failed to fetch quizzes from ${region}:`, error);
    return [];
  }
};

const fetchMultipleQuizzesByRegion = async (regions: string[], gameDifficulty: GameDifficultyType): Promise<Quiz[]> => {
  const quizPromises = regions.map(region => fetchQuizzesByRegion(region, gameDifficulty));
  const quizzesByRegion = await Promise.all(quizPromises);
  return quizzesByRegion.flat().slice(0, QUIZ_COUNT);
};

export const fetchQuizzesByGameMode = async (gameMode: GameModeType, gameDifficulty: GameDifficultyType): Promise<Quiz[]> => {

  if (superRegions[gameMode]) {
    return fetchMultipleQuizzesByRegion(superRegions[gameMode], gameDifficulty);
  }

  switch (gameMode) {
    case GameModeType.EastAsia:
      return fetchQuizzesByRegion('EastAsia', gameDifficulty);
    case GameModeType.SouthAsia:
      return fetchQuizzesByRegion('SouthAsia', gameDifficulty);
    case GameModeType.WestEurope:
      return fetchQuizzesByRegion('WestEurope', gameDifficulty);
    case GameModeType.NorthEurope:
      return fetchQuizzesByRegion('NorthEurope', gameDifficulty);
    case GameModeType.CentralEurope:
      return fetchQuizzesByRegion('CentralEurope', gameDifficulty);
    case GameModeType.Americas:
      return fetchQuizzesByRegion('Americas', gameDifficulty);
    case GameModeType.NorthEastEurope:
      return fetchQuizzesByRegion('NorthEastEurope', gameDifficulty);
    case GameModeType.Balkans:
      return fetchQuizzesByRegion('SouthEastEurope', gameDifficulty);
    default:
      console.error('Invalid game mode');
      return [];
  }
};

const mapDocToLeaderboardMember = (doc: any): LeaderboardMemberType => ({
  id: doc.id,
  country: doc.data().country || 'Not provided',
  username: doc.data().username,
  currentPoints: doc.data().statistics.currentPoints,
  highscoreStreak: doc.data().statistics.highscoreStreak
});

export const fetchTopUsersByPoints = async (): Promise<LeaderboardMemberType[]> => {
  const q = query(USERS_DATABASE, orderBy('statistics.currentPoints', 'desc'), limit(5));
  const querySnapshot = await getDocs(q);
  const result = querySnapshot.docs.map(doc => mapDocToLeaderboardMember(doc))
  return result;
};

export const fetchLeastUsersByPoints = async (): Promise<LeaderboardMemberType[]> => {
  const q = query(USERS_DATABASE, orderBy('statistics.currentPoints', 'asc'), limit(5));
  const querySnapshot = await getDocs(q);
  const result = querySnapshot.docs.map(doc => mapDocToLeaderboardMember(doc))
  return result;
};

export const fetchTopUsersByStreak = async (): Promise<LeaderboardMemberType[]> => {
  const q = query(USERS_DATABASE, orderBy('statistics.highscoreStreak', 'desc'), limit(5));
  const querySnapshot = await getDocs(q);
  const result = querySnapshot.docs.map(doc => mapDocToLeaderboardMember(doc))
  return result;
};

export const fetchOrCreateUser = async (userId: string, email: string, displayName: string | null) => {
  const userDocRef = doc(USERS_DATABASE, userId);
  const userDoc = await getDoc(userDocRef);

  if (!userDoc.exists()) {
    const newUsername = displayName || `Anonymous${Math.floor(Math.random() * 90000 + 10000)}`;
    const newUserType = (email && email.length > 3) ? "authenticated" : "anonymous";
    const subscription = 'Standard'
    const role = 'member';

    await setDoc(userDocRef, {
      username: newUsername,
      email,
      subscription,
      role,
      statistics: {
        currentPoints: 0,
        highscoreStreak: 0
      },
      createdDate: new Date(),
      loginCount: 0,
      friendsList: [],
      userType: newUserType,
    });
    return { username: newUsername, userType: newUserType, subscription };
  } else {
    return {
      username: userDoc.data().username,
      userType: userDoc.data().userType || "anonymous",
      subscription: userDoc.data().subscription || 'Standard',
      email: userDoc.data().email || "",
      role: userDoc.data().role || "member",
    };
  }
};

export const updateUserLoginStats = async (userId: string) => {
  const userDocRef = doc(USERS_DATABASE, userId);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, {
        lastLogin: new Date(),
        loginCount: increment(1)
      });
    } else {
      console.log("No user document found for userId:", userId);
    }
  } catch (error) {
    console.log(error)
  }
};

export const fetchUserStatistics = async (userId: string) => {
  const userDocRef = doc(USERS_DATABASE, userId);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        currentPoints: userData.statistics.currentPoints || 0,
        highscoreStreak: userData.statistics.highscoreStreak || 0
      };
    } else {
      console.log("No user document found for userId: ", userId);
      return { currentPoints: 0, highscoreStreak: 0 };
    }
  } catch (error) {
    console.error("Error fetching user statistics: ", error);
    throw error;
  }
}

export const updateFirestoreUserCurrentPoints = async (userId: string, points: number) => {
  const userDocRef = doc(USERS_DATABASE, userId);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      
      await updateDoc(userDocRef, { "statistics.currentPoints": points });
    } else {
      console.log("No user document found for userId: ", userId);
    }
  } catch (error) {
    console.error("Error updating user currentPoints: ", error);
    throw error;
  }
};

export const updateFirestoreUserHighscoreStreak = async (userId: string, streak: number) => {
  const userDocRef = doc(USERS_DATABASE, userId);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, { "statistics.highscoreStreak": streak });
      console.log(`Updated highscore streak to ${streak} for userId: ${userId}`);
    } else {
      console.log("No user document found for userId: ", userId);
    }
  } catch (error) {
    console.error("Error updating user highscoreStreak: ", error);
    throw error;
  }
};

export const updateFirestoreUsername = async (userId: string, newUsername: string) => {
  const userDocRef = doc(USERS_DATABASE, userId);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, { username: newUsername });
      console.log(`Updated username to ${newUsername} for userId: ${userId}`);
    } else {
      console.log("No user document found for userId: ", userId);
    }
  } catch (error) {
    console.error("Error updating username: ", error);
    throw error;
  }
}

export const deleteQuizFirestoreDocument = async (quizId: string, continent: string, country: string) => {
  try {
    const quizDocRef = doc(QUIZZES_DATABASE, continent, 'quizItems', quizId);
    const statsDocRef = QUIZZES_STATS_DOC;

    await runTransaction(db, async (transaction) => {
      const statsDoc = await transaction.get(statsDocRef);
      if (!statsDoc.exists()) {
        throw new Error("Stats document does not exist!");
      }

      const statsData = statsDoc.data();
      const newTotalCount = (statsData.totalQuizzesCount || 0) - 1;
      const newCountryCount = (statsData[country] || 1) - 1;

      transaction.update(statsDocRef, {
        totalQuizzesCount: newTotalCount,
        [country]: newCountryCount
      });

      transaction.delete(quizDocRef);
    });

    console.log(`Quiz document with ID ${quizId} deleted successfully and stats updated`);
  } catch (error) {
    console.error("Error deleting quiz document and updating stats:", error);
    throw error;
  }
};

export const submitReportFirebase = async (
    userId: string, 
    username:string, 
    email:string,
    quizContinent: string,
    quizCountry: string,
    quizId: string,
    quizUrl: string,
    message:string
) => {
  try {
    const reportCollectionRef = collection(db, 'reports');
    const docRef = await addDoc(reportCollectionRef, {
      userId,
      username,
      email,
      message,
      quizContinent,
      quizCountry,
      quizId,
      quizUrl,
      timestamp: new Date()
    });
    console.log(`Report submitted successfully for user ${username}, Document ID: ${docRef.id}`);
  } catch (error) {
    console.error("Error submitting report:", error);
    throw error;
  }
};

export const increaseCorrectGuess = async (quizId: string, continent: string) => {
  const quizDocRef = doc(QUIZZES_DATABASE, continent, 'quizItems', quizId);

  try {
    const quizDocSnapshot = await getDoc(quizDocRef);
    if (quizDocSnapshot.exists()) {
      await updateDoc(quizDocRef, {
        correctGuesses: increment(1)
      });
    } else {
      console.error(`Quiz document with ID ${quizId} does not exist in the '${continent}' collection.`);
    }
  } catch (error) {
    console.error('Error increasing correct guess count:', error);
  }
};

export const increaseWrongGuess = async (quizId: string, continent: string) => {
  const quizDocRef = doc(QUIZZES_DATABASE, continent, 'quizItems', quizId);

  try {
    const quizDocSnapshot = await getDoc(quizDocRef);
    if (quizDocSnapshot.exists()) {
      await updateDoc(quizDocRef, {
        wrongGuesses: increment(1)
      });
    } else {
      console.error(`Quiz document with ID ${quizId} does not exist in the '${continent}' collection.`);
    }
  } catch (error) {
    console.error('Error increasing wrong guess count:', error);
  }
};