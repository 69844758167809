import ChinaFlag from "../assets/flags/flagChina.jpg"
import JapanFlag from "../assets/flags/flagJapan.jpg"
import KoreaFlag from "../assets/flags/flagKorea.jpg"
import NorthKoreaFlag from "../assets/flags/flagNorthKorea.jpg"
import CanadaFlag from "../assets/flags/flagCanada.png"
import USAFlag from "../assets/flags/flagUSA.png"
import VenezuelaFlag from "../assets/flags/flagVenezuela.png"
import ArgentinaFlag from "../assets/flags/flagArgentina.png"
import ChileFlag from "../assets/flags/flagChile.png"
import BrazilFlag from "../assets/flags/flagBrazil.png"
import MexicoFlag from "../assets/flags/flagMexico.png"
import FranceFlag from "../assets/flags/flagFrance.png"
import ItalyFlag from "../assets/flags/flagItaly.png"
import SpainFlag from "../assets/flags/flagSpain.png"
import PortugalFlag from "../assets/flags/flagPortugal.png"
import IndiaFlag from "../assets/flags/flagIndia.png"
import NepalFlag from "../assets/flags/flagNepal.png"
import PakistanFlag from "../assets/flags/flagPakistan.png"
import BangladeshFlag from "../assets/flags/flagBangladesh.png"
import ThailandFlag from "../assets/flags/flagThailand.png"
import CambodiaFlag from "../assets/flags/flagCambodia.png"
import PhilippinesFlag from "../assets/flags/flagPhilippines.png"
import MyanmarFlag from "../assets/flags/flagMyanmar.png"
import MalaysiaFlag from "../assets/flags/flagMalaysia.png"
import IndonesiaFlag from "../assets/flags/flagIndonesia.png"
import VietnamFlag from "../assets/flags/flagVietnam.png"
import UKFlag from "../assets/flags/flagUK.png";
import IrelandFlag from "../assets/flags/flagIreland.png";
import SwedenFlag from "../assets/flags/flagSweden.png";
import DenmarkFlag from "../assets/flags/flagDenmark.png";
import FinlandFlag from "../assets/flags/flagFinland.png";
import NorwayFlag from "../assets/flags/flagNorway.png";
import NetherlandsFlag from "../assets/flags/flagNetherlands.png";
import GermanyFlag from "../assets/flags/flagGermany.png";
import PolandFlag from "../assets/flags/flagPoland.png";
import CzechRepublicFlag from "../assets/flags/flagCzechRepublic.png";
import AustriaFlag from "../assets/flags/flagAustria.png";
import BelgiumFlag from "../assets/flags/flagBelgium.png";
import LuxembourgFlag from "../assets/flags/flagLuxembourg.png";
import SwitzerlandFlag from "../assets/flags/flagSwitzerland.png";
import MoldovaFlag from "../assets/flags/flagMoldova.png";
import UkraineFlag from "../assets/flags/flagUkraine.png";
import RussiaFlag from "../assets/flags/flagRussia.png";
import BelarusFlag from "../assets/flags/flagBelarus.png";
import LithuaniaFlag from "../assets/flags/flagLithuania.png";
import LatviaFlag from "../assets/flags/flagLatvia.png";
import EstoniaFlag from "../assets/flags/flagEstonia.png";
import CroatiaFlag from "../assets/flags/flagCroatia.png";
import HungaryFlag from "../assets/flags/flagHungary.png";
import SlovakiaFlag from "../assets/flags/flagSlovakia.png";
import BosniaFlag from "../assets/flags/flagBosnia.png";
import SerbiaFlag from "../assets/flags/flagSerbia.png";
import MontenegroFlag from "../assets/flags/flagMontenegro.png";
import KosovoFlag from "../assets/flags/flagKosovo.png";
import AlbaniaFlag from "../assets/flags/flagAlbania.png";
import NorthMacedoniaFlag from "../assets/flags/flagNorthMacedonia.png";
import GreeceFlag from "../assets/flags/flagGreece.png";
import BulgariaFlag from "../assets/flags/flagBulgaria.png";
import RomaniaFlag from "../assets/flags/flagRomania.png";
import SloveniaFlag from "../assets/flags/flagSlovenia.png";
import AsiaIcon from "../assets/flags/iconAsia.png";
import EuropeIcon from "../assets/flags/iconEurope.png";
import GlobeIcon from "../assets/flags/iconGlobe.png";

export const flagMap: { [key: string]: string } = {
  "China": ChinaFlag,
  "Japan": JapanFlag,
  "Korea": KoreaFlag,
  "NorthKorea": NorthKoreaFlag,
  "Canada": CanadaFlag,
  "USA": USAFlag,
  "Venezuela": VenezuelaFlag,
  "Argentina": ArgentinaFlag,
  "Chile": ChileFlag,
  "Brazil": BrazilFlag,
  "Mexico": MexicoFlag,
  "France": FranceFlag,
  "Italy": ItalyFlag,
  "Spain": SpainFlag,
  "Portugal": PortugalFlag,
  "India": IndiaFlag,
  "Nepal": NepalFlag,
  "Pakistan": PakistanFlag,
  "Bangladesh": BangladeshFlag,
  "Thailand": ThailandFlag,
  "Cambodia": CambodiaFlag,
  "Philippines": PhilippinesFlag,
  "Myanmar": MyanmarFlag,
  "Malaysia": MalaysiaFlag,
  "Indonesia": IndonesiaFlag,
  "Vietnam": VietnamFlag,
  "UK": UKFlag,
  "Ireland": IrelandFlag,
  "Sweden": SwedenFlag,
  "Denmark": DenmarkFlag,
  "Finland": FinlandFlag,
  "Norway": NorwayFlag,
  "Netherlands": NetherlandsFlag,
  "Germany": GermanyFlag,
  "Poland": PolandFlag,
  "Czech Republic": CzechRepublicFlag,
  "Austria": AustriaFlag,
  "Belgium": BelgiumFlag,
  "Luxembourg": LuxembourgFlag,
  "Switzerland": SwitzerlandFlag,
  "Moldova": MoldovaFlag,
  "Ukraine": UkraineFlag,
  "Russia": RussiaFlag,
  "Belarus": BelarusFlag,
  "Lithuania": LithuaniaFlag,
  "Latvia": LatviaFlag,
  "Estonia": EstoniaFlag,
  "Croatia": CroatiaFlag,
  "Hungary": HungaryFlag,
  "Slovakia": SlovakiaFlag,
  "Bosnia": BosniaFlag,
  "Serbia": SerbiaFlag,
  "Montenegro": MontenegroFlag,
  "Kosovo": KosovoFlag,
  "Albania": AlbaniaFlag,
  "North Macedonia": NorthMacedoniaFlag,
  "Greece": GreeceFlag,
  "Bulgaria": BulgariaFlag,
  "Romania": RomaniaFlag,
  "Slovenia": SloveniaFlag,
  "Asia": AsiaIcon,
  "Europe": EuropeIcon,
  "Globe": GlobeIcon,
};