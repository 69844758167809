export enum GameModeType {
  EastAsia = "East Asia",
  WestEurope = "West Europe",
  NorthEurope = "North Europe",
  CentralEurope = "Central Europe",
  SouthAsia = "South Asia",
  Americas = "Americas",
  NorthEastEurope = "North East Europe",
  Balkans = "Balkans",
  Europe = "Europe",
  Asia = "Asia",
  Globe = "Globe",
}