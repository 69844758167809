import React, { useState, useEffect, useCallback } from 'react'

import "./Playing.css"

import { useGame } from '../../../../Context/GameContext'
import { useAuth } from '../../../../Context/AuthContext'

import { Quiz } from '../../../../types/Quiz'
import { GameStateType } from '../../../../types/GameStateType'

import GameImages from './GameImages/GameImages'
import GameChoices from './GameChoices/GameChoices'
import GameEnded from './GameEnded/GameEnded'

import { fetchQuizzesByGameMode, deleteQuizFirestoreDocument } from '../../../../services/firestoreService'

import GameControlBar from './GameControlBar/GameControlBar'
import GameAdminBar from './GameAdminBar/GameAdminBar'

const Playing: React.FC = () => {
  const { gameState, setGameState, gameMode, gameDifficulty } = useGame();
  const { user } = useAuth()

  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [currentQuiz, setCurrentQuiz] = useState<Quiz | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [fetchQuizState, setFetchQuizState] = useState("good");

  const fetchQuizzes = useCallback(async () => {
    const fetchedQuizzes = await fetchQuizzesByGameMode(gameMode, gameDifficulty);
    if (fetchedQuizzes.length > 0) {
      setQuizzes(fetchedQuizzes);
      setCurrentQuiz(fetchedQuizzes[0]);
      setCurrentIndex(0);
    } else {
      setFetchQuizState("fail")
      console.log("No quizzes found, try again later.");
    }
  }, [gameMode]);

  const nextQuiz = useCallback(() => {
    if (currentIndex < quizzes.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
      setCurrentQuiz(quizzes[currentIndex + 1]);
    } else {
      console.log("No more quizzes, fetching new quizzes.");
      fetchQuizzes();
    }
  }, [currentIndex, quizzes, fetchQuizzes]);

  const prevQuiz = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
      setCurrentQuiz(quizzes[currentIndex - 1]);
    } else {
      console.log("This is the first quiz.");
    }
  }, [currentIndex, quizzes]);

  const deleteQuiz = useCallback(async () => {
    if (currentQuiz?.id && currentQuiz?.continent) {
      try {
        await deleteQuizFirestoreDocument(currentQuiz.id, currentQuiz.continent, currentQuiz.country);
        setQuizzes(prevQuizzes => prevQuizzes.filter(quiz => quiz.id !== currentQuiz.id));
        nextQuiz();
      } catch (error) {
        console.error("Error deleting quiz:", error);
      }
    } else {
      console.error("Cannot delete quiz: missing id or continent");
    }
  }, [currentQuiz, nextQuiz]);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      nextQuiz();
    } else if (event.key === 'ArrowLeft') {
      prevQuiz();
    } else if (event.key === "Delete" || event.key === "Backspace") {
      deleteQuiz();
    }
  }, [nextQuiz, prevQuiz, deleteQuiz]);

  useEffect(() => {
    if (user?.role === "admin") {
      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [handleKeyPress, user?.role]);

  useEffect(() => {
    fetchQuizzes();
  }, [fetchQuizzes]);

  const handlePlayAgain = () => {
    nextQuiz();
    setGameState(GameStateType.Playing);
  };

  return (
    <div className='Playing'>
      {currentQuiz && (fetchQuizState === "good") ? (
        <>
          <GameImages imageUrl={currentQuiz.imageUrl} quizDifficulty={currentQuiz.difficulty || null} />

          <GameControlBar nextQuiz={nextQuiz} currentQuiz={currentQuiz} />

          {gameState === GameStateType.Playing && (
            <GameChoices 
              gameMode={gameMode}
              correctAnswer={currentQuiz.country}
              quizId={currentQuiz.id}
              continent={currentQuiz.continent}
              nextQuiz={nextQuiz}
              setGameState={setGameState}
            />
          )}

          {gameState === GameStateType.End && (
            <GameEnded handlePlayAgain={handlePlayAgain} />
          )}

          {user?.role === "admin" && 
            <GameAdminBar 
              nextQuiz={nextQuiz}
              prevQuiz={prevQuiz}
              deleteQuiz={deleteQuiz}
            />
          }
        </>
      ) : (
        <p>Loading quizzes, wait or refresh...</p>
      )}
    </div>
  )
}

export default Playing