import React, { useEffect, useState, useRef } from 'react'
import './ProgressBar.css'

import { usePoints } from '../../Context/PointsContext';

import successParticles from '../../assets/animations/successParticles.webm'

const ProgressBar: React.FC = () => {
  const { currentPoints, previousLevelPoints, nextLevelPoints, currentLevelName, lastAnswer } = usePoints();
  const [barColor, setBarColor] = useState('linear-gradient(to right, #0061FF, #60EFFF)');
  const [displayPoints, setDisplayPoints] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  let progressWidth = ((currentPoints - previousLevelPoints) / (nextLevelPoints - previousLevelPoints)) * 100;
  if (currentPoints > 1000 || currentPoints < 0) {
    progressWidth = 100;
  }

  const getBaseColor = () => {
    if (currentPoints > 1000) {
      return 'linear-gradient(to right, #BF953F, #FCF6BA)';
    } else if (currentPoints < 0) {
      return 'linear-gradient(to right, #f44c7d, #d3321d)';
    } else {
      return 'linear-gradient(to right, #0061FF, #60EFFF)';
    }
  };

  const updateColorTemporarily = (color: string) => {
    setBarColor(color);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setBarColor(getBaseColor());
      timeoutRef.current = null;
    }, 1000);
  };

  useEffect(() => {
    setBarColor(getBaseColor());
  }, [currentPoints]);

  useEffect(() => {
    if (lastAnswer !== null) {
      updateColorTemporarily(lastAnswer 
        ? 'linear-gradient(to right, #0061FF, #59d102)' 
        : 'linear-gradient(to right, #0061FF, #e60b09)');
    }

    if (lastAnswer && videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(e => console.error("Error playing video:", e));
    }
  }, [currentPoints, lastAnswer]);


  return (
    <div className='progress-bar'
      onMouseEnter={() => setDisplayPoints(true)} 
      onMouseLeave={() => setDisplayPoints(false)}
    >
      <div className='progress-text-bg'>
        <p>{currentPoints}</p>
        <p id='progress-status'>
           {displayPoints ? `${currentPoints} points` : currentLevelName}
        </p>
        <p>{currentPoints > 1000 ? currentPoints : nextLevelPoints}</p>
      </div>
      <div className="progress-fillup" style={{ width: `${progressWidth}%`, background: barColor }}>
        <div className='progress-fillup-light'></div>
        <video 
          ref={videoRef}
          muted 
          playsInline
          src={successParticles}
          className='video'
          style={{ display: lastAnswer === true ? 'block' : 'none' }}
        />
      </div>
    </div>
  )
}

export default ProgressBar

