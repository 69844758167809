import React, { useState, useEffect } from 'react'

import LeaderboardIcon from '@mui/icons-material/Leaderboard';

import { LeaderboardMemberType } from '../../../types/LeaderboardMemberType';

import "./Leaderboard.css"

import LeaderboardMember from './LeaderboardMember/LeaderboardMember';

import { fetchTopUsersByPoints, fetchLeastUsersByPoints, fetchTopUsersByStreak } from '../../../services/firestoreService';

import { useAnalyticsContext } from '../../../Context/AnalyticsContext';

const Leaderboard: React.FC = () => {
  const { logAnalyticsEvent } = useAnalyticsContext();

  const [leaderboardMembers, setLeaderboardMembers] = useState<LeaderboardMemberType[]>([]);
  const [leaderboardType, setLeaderboardType] = useState<"Least" | "Most" | "Streak">("Least");

  useEffect(() => {
    const fetchData = async () => {
      handleLeaderboardChange(leaderboardType);
    };
    fetchData();
  }, [leaderboardType]);

  const handleLeaderboardChange = async (leaderboard: "Least" | "Most" | "Streak") => {
    let members: LeaderboardMemberType[];

    try {
      switch (leaderboard) {
        case "Least":
          members = await fetchTopUsersByPoints();
          break;
        case "Most":
          members = await fetchLeastUsersByPoints();
          break;
        case "Streak":
          members = await fetchTopUsersByStreak();
          break;
        default:
          members = [];
      }
      setLeaderboardMembers(members);
      logAnalyticsEvent('leaderboard_data_loaded', { 
        leaderboard_type: leaderboard,
        members_count: members.length
      });
    } catch (error) {
      console.error("Failed to fetch leaderboard data:", error);
      setLeaderboardMembers([]);
      logAnalyticsEvent('leaderboard_data_error', { 
        leaderboard_type: leaderboard,
        error_message: (error as Error).message
      });
    }
  }

  const handleLeaderboardTypeClick = (type: "Least" | "Most" | "Streak") => {
    logAnalyticsEvent('leaderboard_type_change', { 
      previous_type: leaderboardType,
      new_type: type
    });
    setLeaderboardType(type);
  }

  return (
    <div className='Leaderboard'>
      <div className="leaderboard-title">
        <LeaderboardIcon className='leaderboardIcon' sx={{ fontSize: 20 }} />
        <h2>Leaderboards</h2>
      </div>
      <div className='leaderboard-types'>
        <button onClick={() => handleLeaderboardTypeClick("Least")}
                className={leaderboardType === "Least" ? 'selected' : ''}>
                  {leaderboardType === "Least" ? 'Least Racist' : 'Least'}
        </button>
        <button onClick={() => handleLeaderboardTypeClick("Most")}
                className={leaderboardType === "Most" ? 'selected' : ''}>
                  {leaderboardType === "Most" ? 'Most Racist' : 'Most'}
                </button>
        <button onClick={() => handleLeaderboardTypeClick("Streak")}
                className={leaderboardType === "Streak" ? 'selected' : ''}>
                  {leaderboardType === "Streak" ? 'Highest Streak' : 'Streak'}
                </button>
      </div>
      <div className="leaderboard-members">
        {leaderboardMembers.map((member, index) => (
          <LeaderboardMember key={index} member={member} leaderboardType={leaderboardType} />
        ))}
      </div>
    </div>
  )
}

export default Leaderboard