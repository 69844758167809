import React from 'react'

import "./Instructions.css"

import { GameStateType } from '../../../../types/GameStateType';

import SpecialButton from '../../../SpecialButton/SpecialButton'

interface InstructionsProps {
  GameState: typeof GameStateType;
  setGameState: (state: GameStateType) => void;
}
const Instructions: React.FC<InstructionsProps> = ({ GameState, setGameState}) => {

  return (
    <div className='Instructions'>
      <p><span>Are You racist?</span> is just a little game where you learn to distinguish nationalities. It's meant for entertainment and education, not offence.</p>
      <SpecialButton text='I understand, Start' onClick={() => setGameState(GameState.Playing)} />
    </div>
  )
}

export default Instructions