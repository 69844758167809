import { useState, useEffect } from 'react';
import { updateFirestoreUserHighscoreStreak, updateFirestoreUserCurrentPoints, increaseCorrectGuess, increaseWrongGuess } from '../services/firestoreService';

const levels = [0, 50, 100, 250, 1000];
const levelNames = ["You are super racist", "You are racist", "You are a bit less racist", "You are not racist", "You are a tryhard"];

export const usePointsSystem = ( userId: string, initialCurrentPoints: number, initialHighscoreStreak: number, initialSkips: number) => {
  const [currentPoints, setCurrentPoints] = useState(initialCurrentPoints);
  const [gameStreak, setGameStreak] = useState(0);
  const [highscoreStreak, setHighscoreStreak] = useState(initialHighscoreStreak);
  const [lastAnswer, setLastAnswer] = useState<boolean | null>(null);
  const [skipCount, setSkipCount] = useState(initialSkips);

  useEffect(() => {
    setCurrentPoints(initialCurrentPoints);
  }, [initialCurrentPoints]);

  useEffect(() => {
    setHighscoreStreak(initialHighscoreStreak);
  }, [initialHighscoreStreak]);

  useEffect(() => {
    setSkipCount(initialSkips)
  }, [initialSkips])

  const currentLevelIndex = levels.findIndex((level, index) => {
    if (currentPoints < 0) return index === 0;
    if (index === levels.length - 1) return true;
    return currentPoints >= level && currentPoints < levels[index + 1];
  });

  const previousLevelPoints = levels[Math.max(currentLevelIndex, 0)];
  const nextLevelPoints = levels[Math.min(currentLevelIndex + 1, levels.length - 1)];
  const currentLevelName = levelNames[currentLevelIndex];

  const handleCorrectAnswer = (quizId : string, continent: string) => {
    increaseCorrectGuess(quizId, continent)
    setCurrentPoints((current: number) => current + 10);
    updateFirestoreUserCurrentPoints(userId, currentPoints + 10)
    setGameStreak(streak => streak + 1);
    setLastAnswer(true)
  };

  const handleIncorrectAnswer = (quizId : string, continent: string) => {
    handleUpdateHighScoreStreak(gameStreak)
    increaseWrongGuess(quizId, continent)
    setCurrentPoints((current: number) => current - 5);
    updateFirestoreUserCurrentPoints(userId, currentPoints - 10)
    setGameStreak(0);
    setSkipCount(initialSkips);
    setLastAnswer(false)
  };

  const handleUpdateHighScoreStreak = (newStreak: number) => {
    if (newStreak > highscoreStreak) {
      setHighscoreStreak(newStreak);
      updateFirestoreUserHighscoreStreak(userId, newStreak)
    }
  };

  const handleSkipCountUsed = () => {
    setSkipCount(current => current - 1);
  }
  
  return {
    currentPoints,
    previousLevelPoints,
    nextLevelPoints,
    currentLevelName,
    gameStreak,
    highscoreStreak,
    lastAnswer,
    skipCount,
    handleSkipCountUsed,
    handleCorrectAnswer,
    handleIncorrectAnswer,
  };
};
