import React, { useEffect, useState } from 'react'

import './GameControlBar.css'

import GameStreak from '../../GameStreak/GameStreak'

import ReportIcon from '@mui/icons-material/Report';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ControlButton from './ControlButton/ControlButton';

import { usePoints } from '../../../../../Context/PointsContext';
import MessageSubmitModal from '../../../../MessageSubmitModal/MessageSubmitModal';

import { Quiz } from '../../../../../types/Quiz';

import { increaseWrongGuess } from '../../../../../services/firestoreService';

interface GameControlProps {
  nextQuiz: () => void,
  currentQuiz: Quiz;
}

const GameControlBar: React.FC<GameControlProps> = ({ nextQuiz, currentQuiz }) => {
  const { skipCount, handleSkipCountUsed } = usePoints()

  const [skipsExhausted, setSkipsExhausted] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (skipCount < 1) {
      setSkipsExhausted(true)
    } else {
      setSkipsExhausted(false)
    }
  }, [skipCount])

  const handleToggleModal = () => {
    setOpenModal(current => !current)
  }

  const handleSkipClick = () => {
    if (skipCount >= 1) {
      nextQuiz()
      handleSkipCountUsed()
      increaseWrongGuess(currentQuiz.id, currentQuiz.continent)
    }
  }

  return (
    <div className='GameControlBar'>
      <div className="modalContainer">
        <ControlButton 
          text={"Report"} 
          icon={<ReportIcon sx={{ fontSize: 20 }} />}
          onClick={handleToggleModal}
          className={'controlButton'}
        />
        {openModal && <MessageSubmitModal handleToggleModal={handleToggleModal} currentQuiz={currentQuiz} />}
      </div>

      <GameStreak />
      
      <ControlButton 
        text={skipCount > 1 ? `${skipCount} Skips` : "Skip"}
        icon={<DoubleArrowIcon sx={{ fontSize: 20 }} />}
        onClick={handleSkipClick}
        className={skipsExhausted ? 'controlButtonLocked' : 'controlButton'}
      />
    </div>
  )
}

export default GameControlBar