import React from 'react'

import "./GameStreak.css"

import { usePoints } from '../../../../Context/PointsContext'

import FireIcon from '../../../../assets/fire.png';

const GameStreak: React.FC = () => {

  const { gameStreak } = usePoints();

  return (
    <div className='GameStreak'>
      <img src={FireIcon} alt='Streak Icon'/>
      <h2>{gameStreak}</h2>
    </div>
  )
}

export default GameStreak