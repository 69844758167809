// App.tsx

import React, { useState, useCallback } from 'react';
import './App.css';

import Navbar from './components/Navbar/Navbar';
import Body from './components/Body/Body';
import Footer from './components/Footer/Footer';

import LoginModal from './components/LoginModal/LoginModal';

import { PointsProvider } from './Context/PointsContext';
import { GameProvider } from './Context/GameContext';
import { AuthProvider } from './Context/AuthContext';
import { AnalyticsProvider } from './Context/AnalyticsContext';

document.body.style.backgroundColor = "#25272A";

const App: React.FC = () => {
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const handleOpenLoginModal = useCallback((state: boolean) => {
    setOpenLoginModal(state);
  }, []);

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <GameProvider>
          <PointsProvider>
            <div className="App">
              <Navbar setOpenLoginModal={handleOpenLoginModal} />
              <Body />
              <Footer />
              {openLoginModal && (
                <LoginModal setOpenLoginModal={handleOpenLoginModal} />
              )}
            </div>
          </PointsProvider>
        </GameProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

export default App;