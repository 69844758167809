import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { 
  User as FirebaseUser,
  GoogleAuthProvider, 
  signInWithPopup, 
  onAuthStateChanged, 
  signInAnonymously,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signOut as firebaseSignOut
} from 'firebase/auth';

import { auth } from '../firebase/firebase-config';
import { fetchOrCreateUser, updateUserLoginStats, fetchUserStatistics, updateFirestoreUsername } from '../services/firestoreService';
import { GameStateType } from '../types/GameStateType';
import { useGame } from './GameContext';

interface User {
  uid: string;
  email: string | null;
  username: string;
  userType: string;
  subscription: string;
  role: string;
  statistics: {
    currentPoints: number;
    highscoreStreak: number;
  };
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signInWithGoogle: () => Promise<void>;
  signInWithEmailPassword: (email: string, password: string) => Promise<void>;
  createUserWithEmailPassword: (email: string, password: string) => Promise<void>;
  signOut: (callback?: () => void) => Promise<void>;
  updateUserState: (user: FirebaseUser | User) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { setGameState } = useGame();
  const [anonymousSignInProgress, setAnonymousSignInProgress] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribe();
  }, []);

  const handleAuthStateChange = async (firebaseUser: FirebaseUser | null) => {
    if (firebaseUser) {
      await updateUserState(firebaseUser);
    } else if (!anonymousSignInProgress) {
      setAnonymousSignInProgress(true);
      await signInAnonymously(auth);
    }
    setLoading(false);
  };

  const updateUserState = async (updatedUser: FirebaseUser | User) => {
    let newUser: User;

    if ('uid' in updatedUser && 'email' in updatedUser && 'username' in updatedUser) {
      newUser = updatedUser as User;
    } else {
      const { uid, email, displayName } = updatedUser as FirebaseUser;
      const { username, userType, subscription, role } = await fetchOrCreateUser(uid, email || "", displayName);
      const { currentPoints, highscoreStreak } = await fetchUserStatistics(uid);

      newUser = {
        uid,
        email,
        username,
        userType,
        subscription,
        role,
        statistics: { currentPoints, highscoreStreak },
      };
    }

    setUser(newUser);
    await updateUserLoginStats(newUser.uid);
    
    if ('username' in updatedUser) {
      await updateFirestoreUsername(newUser.uid, newUser.username);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      await updateUserState(result.user);
    } catch (error) {
      console.error("Google sign-in error:", error);
      throw error;
    }
  };

  const signInWithEmailPassword = async (email: string, password: string) => {
    try {
      const userCredential = await firebaseSignInWithEmailAndPassword(auth, email, password);
      await updateUserState(userCredential.user);
    } catch (error) {
      console.error("Email/Password sign-in error:", error);
      throw error;
    }
  };

  const createUserWithEmailPassword = async (email: string, password: string) => {
    try {
      const userCredential = await firebaseCreateUserWithEmailAndPassword(auth, email, password);
      await updateUserState(userCredential.user);
    } catch (error) {
      console.error("Error creating user with email and password:", error);
      throw error;
    }
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    setUser(null);
    setGameState(GameStateType.Instructions);
    setAnonymousSignInProgress(false);
    await signInAnonymously(auth);
  };

  const contextValue: AuthContextType = {
    user,
    loading,
    signInWithGoogle,
    signInWithEmailPassword,
    createUserWithEmailPassword,
    signOut,
    updateUserState,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};