import React from 'react'

import "./Game.css"

import { useGame } from '../../../Context/GameContext'
import { GameStateType } from '../../../types/GameStateType'

import Instructions from './Instructions/Instructions'
import Playing from './Playing/Playing'

const Game: React.FC = () => {
  const { gameState, setGameState } = useGame();

  return (
    <div className="Game">
      {gameState === "INSTRUCTIONS" ? 
        (<Instructions GameState={GameStateType} setGameState={setGameState} />)
        : 
        (null)
      }
      {gameState === "PLAYING" || gameState === "END" ?
        (<Playing />)
        :
        (null)
      }
    </div>
  )
}

export default Game