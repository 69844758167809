import { GameStateType } from "../types/GameStateType";
import { superRegions } from "../config/superRegions";
import { quizOptionsObject } from "../config/quizOptionsObject";
import { GameModeType } from "../types/GameModeType";

const modeKeyMap: Record<GameModeType, string> = {
  [GameModeType.EastAsia]: "EastAsia",
  [GameModeType.WestEurope]: "WestEurope",
  [GameModeType.NorthEurope]: "NorthEurope",
  [GameModeType.CentralEurope]: "CentralEurope",
  [GameModeType.SouthAsia]: "SouthAsia",
  [GameModeType.Americas]: "Americas",
  [GameModeType.NorthEastEurope]: "NorthEastEurope",
  [GameModeType.Balkans]: "Balkans",
  [GameModeType.Europe]: "Europe",
  [GameModeType.Asia]: "Asia",
  [GameModeType.Globe]: "Globe",
};

interface CheckAnswerArgs {
  option: string;
  correctAnswer: string;
  quizId: string;
  continent: string,
  handleCorrectAnswer: (quizId: string, continent: string) => void;
  handleIncorrectAnswer: (quizId: string, continent: string) => void;
  nextQuiz: () => void;
  setGameState: (state: GameStateType) => void;
  currentPoints: number;
  gameStreak: number;
}

export const checkAnswer = ({
  option,
  correctAnswer,
  quizId,
  continent,
  handleCorrectAnswer,
  handleIncorrectAnswer,
  nextQuiz,
  setGameState,
  currentPoints,
  gameStreak,
}: CheckAnswerArgs) => {
  if (option === correctAnswer) {
    handleCorrectAnswer(quizId, continent);
    nextQuiz();
  } else {
    handleIncorrectAnswer(quizId, continent);
    setGameState(GameStateType.End);
  }
};

export const getRandomOptions = (correctAnswer: string, gameMode: GameModeType ): string[] => {
  const options: string[] = [];
  let allCountries: string[] = [];

  const gameModeKey = modeKeyMap[gameMode]; // Use mapping to get the correct key

  // Check if gameMode is a superRegion
  if (superRegions[gameModeKey]) {
    // Accumulate all countries from regions within the superRegion
    superRegions[gameModeKey].forEach(region => {
      allCountries = allCountries.concat(quizOptionsObject[region]);
    });
  } else {
    allCountries = quizOptionsObject[gameModeKey];
  }

  // Filter out the correct answer to prepare for random selection
  let availableOptions = allCountries.filter(option => option !== correctAnswer);

  // Select 3 random options different from the correct answer
  while (options.length < 3) {
    const randomIndex = Math.floor(Math.random() * availableOptions.length);
    options.push(availableOptions[randomIndex]);
    availableOptions.splice(randomIndex, 1); // Remove the selected option to avoid duplicates
  }

  // Add the correct answer to the options array
  options.push(correctAnswer);

  // Shuffle the options array to ensure the correct answer is randomly placed
  for (let i = options.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [options[i], options[j]] = [options[j], options[i]];
  }

  return options;
};