import React, { useEffect, useState } from 'react'

import "./Gamemodes.css"

import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import { useGame } from '../../../Context/GameContext';
import { GameModeType } from '../../../types/GameModeType';
import { GameStateType } from '../../../types/GameStateType';

import Gamemode from './Gamemode/Gamemode';

const Gamemodes: React.FC = () => {
  const { gameMode, setGameMode, setGameState } = useGame()
  const [showGradient, setShowGradient] = useState(false);

  useEffect(() => {
    const checkDimensions = () => {
      const { innerHeight, innerWidth } = window;
      setShowGradient(
        innerWidth >= 800 && (
          innerHeight < 800 || 
          (innerHeight < 1000 && innerWidth <= 1300)
        )
      );
    };

    checkDimensions();
    window.addEventListener('resize', checkDimensions);

    return () => window.removeEventListener('resize', checkDimensions);
  }, []);

  const handleModeClick = (selectedGameMode: GameModeType) => {
    setGameMode(selectedGameMode)
    setGameState(GameStateType.Instructions)
  };

  return (
    <div className='Gamemodes'>
      <div className="gamemodes-title">
        <VideogameAssetIcon className='gamemodesIcon' sx={{ fontSize: 20 }} />
        <h2>Gamemodes</h2>
      </div>
      <div className="gamemodes-container">
        {Object.values(GameModeType).map(mode => (
          <Gamemode
            key={mode}
            region={mode}
            className={gameMode === mode ? "GamemodeSelected" : "Gamemode"}
            onClick={() => handleModeClick(mode)}
          />
        ))}
        {showGradient && (<div className="marginElement"></div>)}
        {showGradient && (<div className="marginElement2"></div>)}
      </div>
      {showGradient && (<div className="fadingGradient"></div>)}
    </div>
  )
}

export default Gamemodes