import React from 'react'

import "./AYRLogo.css"

const AYRLogo: React.FC = () => {
  return (
    <h1>Are You Racist?</h1>
  )
}

export default AYRLogo;