import React, {useState, useEffect} from 'react'

import "./Body.css"

import Leaderboard from './Leaderboard/Leaderboard'
import Game from './Game/Game'
import Gamemodes from './Gamemodes/Gamemodes'
import Difficulty from './Difficulty/Difficulty'

const Body: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={isMobile ? 'Body-mobile' : 'Body-desktop'}>
      {isMobile ? (
        <>
          <Game />
          <div className="difficulty-leaderboard-container">
            <Difficulty />
            <Leaderboard />
          </div>
          <Gamemodes />
        </>
      ) : (
        <>
          <div className="difficulty-leaderboard-container">
            <Difficulty />
            <Leaderboard />
          </div>
          <Game />
          <Gamemodes />
        </>
      )}
    </div>
  );
};

export default Body