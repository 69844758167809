import React from 'react'

import './Choice.css'

import { flagMap } from '../../../../../../config/flagData'

interface ChoiceProps {
  country: string,
  checkAnswer: (country: string) => void;
  isCorrect: boolean;
  showCorrect: boolean;
}

const Choice: React.FC<ChoiceProps> = ({ country, checkAnswer, isCorrect, showCorrect }) => {
  const flagImage = flagMap[country];
  
  const className = `Choice ${showCorrect && isCorrect ? 'correct' : ''}`;

  const countryDisplayMap: { [key: string]: string } = {
    "North Macedonia": "N. Macedonia",
    "Czech Republic": "C. Republic",
    "NorthKorea": "N. Korea"
  };

  const displayCountry = countryDisplayMap[country] || country;

  return (
    <button className={className} onClick={() => checkAnswer(country)}>
      <img src={flagImage} alt={`${country} flag`} />
      <p>{displayCountry}</p> 
    </button>
  );
}

export default Choice;