import React from 'react'

import "./EditButton.css"

interface EditButtonProps {
  handleEditing: () => void; 
  confirmEditUsername: () => void;
  editingUsername: boolean;
  icon: JSX.Element;  
}

const EditButton: React.FC<EditButtonProps> = ({ handleEditing, confirmEditUsername, editingUsername, icon }) => {
  return (
    <button className={editingUsername ? 'ConfirmButton' : 'EditButton'} onClick={editingUsername ? confirmEditUsername : handleEditing}>
      {icon}
    </button>
  )
}

export default EditButton