import React from 'react'

import "./GameEnded.css"

import { usePoints } from '../../../../../Context/PointsContext'

interface GameEndedProps {
  handlePlayAgain: () => void,
}

const GameEnded: React.FC<GameEndedProps> = ({ handlePlayAgain }) => {
  const { highscoreStreak } = usePoints();

  return (
    <button className='GameEnded' onClick={handlePlayAgain}>
      <h2>Try again, best streak: {highscoreStreak}</h2>
    </button>
  )
}

export default GameEnded