import React, { createContext, useContext, ReactNode } from 'react';
import { usePointsSystem } from '../Hooks/usePointsSystem';

import { useAuth } from './AuthContext';

interface PointsSystemContext {
  currentPoints: number;
  previousLevelPoints: number;
  nextLevelPoints: number;
  currentLevelName: string;
  gameStreak: number;
  highscoreStreak: number;
  lastAnswer: boolean | null;
  skipCount: number;
  handleSkipCountUsed: () => void;
  handleCorrectAnswer: (quizId: string, continent: string) => void;
  handleIncorrectAnswer: (quizId: string, continent: string) => void;
}

const defaultContext: PointsSystemContext = {
  currentPoints: 0,
  previousLevelPoints: 0,
  nextLevelPoints: 50,
  currentLevelName: 'You Are Racist',
  gameStreak: 0,
  highscoreStreak: 0,
  lastAnswer: null,
  skipCount: 1,
  handleSkipCountUsed: () => {},
  handleCorrectAnswer: (quizId: string, continent: string) => {},
  handleIncorrectAnswer: (quizId: string, continent: string) => {},
};

const PointsContext = createContext<PointsSystemContext>(defaultContext);

interface PointsProviderProps {
  children: ReactNode;
}

export const PointsProvider: React.FC<PointsProviderProps> = ({ children }) => {
  const { user } = useAuth();

  const userAllowedSkips = user?.subscription === "Premium" ? 5 : 1

  const pointsHook = usePointsSystem(user?.uid || "", user?.statistics.currentPoints || 0, user?.statistics.highscoreStreak || 0, userAllowedSkips);

  return (
    <PointsContext.Provider value={pointsHook}>
      {children}
    </PointsContext.Provider>
  );
};

export const usePoints = () => useContext(PointsContext);
