import React from 'react'

import "./Footer.css"

const Footer: React.FC = () => {
  return (
    <div className='Footer'>
      <a 
        href="https://docs.google.com/document/d/1aHn96cZau-a1hOPhtiuFNmlyiXjL7WllKmJZ1oWcPmE/pub"
        target='_blank'
      > Privacy Policy</a>
      <p>👻</p>
      <a 
        href="#"
        target='_blank'
      > Terms of Service</a>
    </div>
  )
}

export default Footer