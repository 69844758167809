import React from 'react'

import './BasicButton.css'

interface BasicButtonProps {
  text: string;
  icon?: React.ReactElement;
  onClick: () => void;
}

const BasicButton: React.FC<BasicButtonProps> = ({ text, icon, onClick }) => {
  const getButtonClass = (text: string) => {
    switch (text) {
      case "Logout":
        return "LogoutButton";
      case "Login":
        return "LoginButton";
      case "Submit":
        return "SubmitButton";
      case "Discord":
        return "DiscordButton"
      case "Join our Discord":
        return "DiscordButton"
      default:
        return "BasicButton";
    }
  };

  const buttonClass = getButtonClass(text);

  return (
    <button className={buttonClass} onClick={onClick}>
      {icon}
      {text}
    </button>
  );
};

export default BasicButton;
