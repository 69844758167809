import React from 'react'

import "./LeaderboardMember.css"

import { LeaderboardMemberType } from '../../../../types/LeaderboardMemberType'

import { useAuth } from '../../../../Context/AuthContext'

interface LeaderboardMemberProps {
  member: LeaderboardMemberType,
  leaderboardType: string,
}

const LeaderboardMember: React.FC<LeaderboardMemberProps> = ({ member, leaderboardType }) => {
  const { user } = useAuth();

  const memberIsHim = member.id === user?.uid

  return (
    <div className='LeaderboardMember'>
      <p className={memberIsHim ? "memberIsHim" : "memberIsNotHim" }>
        {memberIsHim ? `• ${member.username}` : member.username}
      </p>
      {leaderboardType === "Streak" ? 
        (<p className='leaderboard-points'>{member.highscoreStreak}</p>)
        :
        (<p className='leaderboard-points'>{member.currentPoints}</p>)
      }
    </div>
  )
}

export default LeaderboardMember