import React, { useState, useEffect } from 'react'

import './Navbar.css';

import LoginIcon from '@mui/icons-material/Login';
import FaceIcon from '@mui/icons-material/Face';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DiscordIcon from '../../assets/discordLogo.png'

import BasicButton from '../BasicButton/BasicButton';
import EditButton from '../ProfileModal/EditButton/EditButton';
import ProgressBar from '../ProgressBar/ProgressBar';
import AYRLogo from '../AYRLogo/AYRLogo';

import { useAuth } from '../../Context/AuthContext';

interface NavbarProps {
  setOpenLoginModal: (open: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ setOpenLoginModal }) => {
  const { user, signOut, updateUserState } = useAuth();
  const [iconSize, setIconSize] = useState(20);
  const [isMobile, setIsMobile] = useState(false);

  const [editingUsername, setEditingUsername] = useState(false);
  const [username, setUsername] = useState<string | null>(null);

  const handleOpenDiscord = () => {
    window.open("https://discord.gg/XmMBS32yB4", '_blank')
  };

  const isAuthenticatedUser = user?.userType === "authenticated";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 930);
      if (window.innerWidth < 630) {
        setIconSize(20);
      } else if (window.innerWidth < 800) {
        setIconSize(15);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  const handleEditingUsername = () => {
    setEditingUsername(!editingUsername);
  }

  const confirmEditUsername = async (newUsername: string) => {
    newUsername = newUsername.trim();
    if (!newUsername) {
      alert("Username cannot be empty")
      return;
    }
    if (newUsername.length >= 15) {
      alert('Username must be shorter than 15 characters.');
      return;
    }
    if (newUsername !== user?.username && user) {
      const updatedUser = {
        ...user,
        username: newUsername
      };

      console.log("success")
        
      await updateUserState(updatedUser);
    }
    setEditingUsername(false);
  };

  return (
    <nav className="Navbar">
      {isMobile ? (
        <div className="mobile-navbar">
          <div className="mobile-top">
            <BasicButton text='Discord' icon={
              <img src={DiscordIcon} className='discord-icon' alt='discord'/>
            } onClick={handleOpenDiscord}/>

            <div className="login-container">
              <div className="profile-section">
                <div className="profile-element" style={{justifyContent: 'flex-start'}}>
                  <FaceIcon sx={{ fontSize: 20 }} />
                  {editingUsername ? (
                    <input 
                      autoFocus
                      type="text" 
                      value={username ?? ""}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  ) : (
                    <p>{username}</p>
                  )}
                </div>
                <EditButton 
                  handleEditing={handleEditingUsername} 
                  confirmEditUsername={() => confirmEditUsername(username || "")}
                  editingUsername={editingUsername} 
                  icon={editingUsername ? <CheckIcon /> : <EditIcon />}
                />
              </div>
              
              {isAuthenticatedUser ? (
                  <BasicButton text='Logout' icon={<ExitToAppIcon sx={{ fontSize: 20 }} />} onClick={signOut} />
                ) : (
                  <BasicButton text="Login" icon={<LoginIcon sx={{ fontSize: iconSize }} />} onClick={() => setOpenLoginModal(true)} />
              )}
            </div>
          </div>
          <div className='nav-middle'>
            <AYRLogo />
            <ProgressBar />
          </div>
        </div>
      ) : (
        <div className="desktop-navbar">
          <div className="basicbutton-container">
            <BasicButton text='Join our Discord' icon={
              <img src={DiscordIcon} className='discord-icon' alt='discord'/>
            } onClick={handleOpenDiscord}/>
          </div>
          <div className='nav-middle'>
            <AYRLogo />
            <ProgressBar />
          </div>
          <div className="login-container">
            <div className="profile-section">
              <div className="profile-element" style={{justifyContent: 'flex-start'}}>
                <FaceIcon sx={{ fontSize: 20 }} />
                {editingUsername ? (
                  <input 
                    autoFocus
                    type="text" 
                    value={username ?? ""}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                ) : (
                  <p>{username}</p>
                )}
              </div>
              <EditButton 
                handleEditing={handleEditingUsername} 
                confirmEditUsername={() => confirmEditUsername(username || "")}
                editingUsername={editingUsername} 
                icon={editingUsername ? <CheckIcon /> : <EditIcon />}
              />
            </div>

            {isAuthenticatedUser ? (
                <BasicButton text='Logout' icon={<ExitToAppIcon sx={{ fontSize: 20 }} />} onClick={signOut} />
              ) : (
                <BasicButton text="Login" icon={<LoginIcon sx={{ fontSize: iconSize }} />} onClick={() => setOpenLoginModal(true)} />
            )}
          </div>
        </div>
      )}

    </nav>
  )
}

export default Navbar;