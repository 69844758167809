import React from 'react'

import "./Gamemode.css"

import { flagMap } from '../../../../config/flagData'
import { quizOptionsObject } from '../../../../config/quizOptionsObject'
import { superRegions } from '../../../../config/superRegions'

import { GameModeType } from '../../../../types/GameModeType'

interface GamemodeProps {
  region: string,
  className: string,
  onClick: () => void;
}

const Gamemode: React.FC<GamemodeProps> = ({ region, className, onClick }) => {
  const typeKey = Object.keys(GameModeType).find(key => GameModeType[key as keyof typeof GameModeType] === region) as keyof typeof quizOptionsObject;
  
  let displayCountries: string[] = [];
  let remainingCount = 0
  let isSuperRegion = false

  if (Object.keys(superRegions).includes(typeKey)) {
    remainingCount = 0
    isSuperRegion = true
  } else {
    const countries = quizOptionsObject[typeKey] || [];
    displayCountries = countries.slice(0, 4);
    remainingCount = countries.length > 4 ? countries.length - 4 : 0;
  }

  return (
    <button className={className} onClick={onClick}>
      <p>{region}</p>
      <div className="countriesContainer">
        {isSuperRegion ? (
          <img 
            src={flagMap[region]} 
            alt={region} 
            className='gamemodeSuperRegion' 
          />
        ): (
          displayCountries.map((country, index) => (
            <img 
              key={index}
              src={flagMap[country]} 
              alt={country} 
              className='gamemodeCountry' 
            />
          ))
        )}

        {remainingCount > 0 && (
          <div className="gamemodeCountry moreCountries">
            <p>{remainingCount}+</p>
          </div>
        )}
      </div>
    </button>
  )
}

export default Gamemode