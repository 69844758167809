export const quizOptionsObject: Record<string, string[]> = {
  "Americas": ["Canada", "USA", "Venezuela", "Argentina", "Chile", "Brazil", "Mexico"],
  "WestEurope": ["France", "Italy", "Spain", "Portugal"],
  "NorthEurope": ["UK", "Ireland", "Sweden", "Denmark", "Finland", "Norway", "Netherlands"],
  "EastAsia": ["Korea", "China", "Japan", "NorthKorea"],
  "CentralEurope": ["Germany", "Poland", "Slovakia", "Slovenia", "Czech Republic", "Austria", "Belgium", "Luxembourg", "Switzerland"],
  "SouthAsia": ["India", "Nepal", "Pakistan", "Bangladesh","Thailand", "Cambodia", "Philippines", "Myanmar", "Malaysia", "Indonesia", "Vietnam"],
  "NorthEastEurope": ["Moldova", "Ukraine", "Russia", "Belarus", "Lithuania", "Latvia", "Estonia"],
  "Balkans": ["Croatia", "Hungary", "Bosnia", "Serbia", "Montenegro", "Kosovo", "Albania", "North Macedonia", "Greece", "Bulgaria", "Romania"],
};
